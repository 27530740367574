import React from 'react';
import { X as CloseIcon } from 'react-feather';
import { useHistory } from 'react-router';

import { Breakpoints } from '../../../../dictionaries';

import TopRightCornerButton from './TopRightCornerButton';

interface Props {
  className?: string;
  mobileBreakpoint?: Breakpoints;
}

const ClosePageButton: React.FC<Props> = ({ className, mobileBreakpoint }) => {
  const history = useHistory();

  return (
    <TopRightCornerButton
      onClick={() => history.push('/')}
      className={className}
      mobileBreakpoint={mobileBreakpoint}
    >
      <CloseIcon />
    </TopRightCornerButton>
  );
};

export default ClosePageButton;
