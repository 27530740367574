import styled from 'styled-components';

import { Breakpoints } from '../../../dictionaries';
import IconButton from '../../../components/shared/IconButton/IconButton';

export const ActionButton = styled(IconButton)`
  &.active,
  &:hover {
    background: ${(props) => props.theme.colors.secondaryLight};
  }
  
  @media (min-width: ${Breakpoints.lg}px) {
    min-width: 15.3125rem;
  }
`;
