import React from 'react';

import { FEATURES } from '../../menus';
import { Container } from './styled';

import SidebarMenuContainer from '../../containers/SidebarMenuContainer';

const FeaturesMenu: React.FC = () => {
  return (
    <Container>
      <SidebarMenuContainer
        menuItems={FEATURES.map((feature) => ({
          link: feature.link,
          text: feature.text,
          descText: feature.descText,
          icon: <feature.icon />,
        }))}
        withMargin
      />
    </Container>
  );
};

export default FeaturesMenu;
