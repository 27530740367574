import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { format } from 'date-fns';
import { getActivityFilters } from '../../../store/user/selectors';
import { config } from '../../../config';
import {
  getAccounts,
  getTxs,
  getAccountById,
  getTxByHash,
} from '../../../store/account/selectors';
import { usePrevious } from '../../../utils/hooks';
import { Transaction } from '../../../store/account/types';
import { getTxDetails } from '../../../store/account/actions';

import {
  AdditionalInfo,
  AdditionalInfoComment,
  AdditionalInfoDate,
  Details,
  Placeholder,
  TopUp,
  TopUpHeader,
  TopUpHeaderDetails,
  TopUpHeaderIcon,
} from './styled';

import PageContent from '../../layout/PageContent/PageContent';
import LabeledText from '../LabeledText/LabeledText';
import CurrencyIcon from '../CurrencyIcon/CurrencyIcon';
import Hash from '../Hash/Hash';
import CopyText from '../CopyText/CopyText';
import ExternalLink from '../ExternalLink/ExternalLink';
import TxComment from './TxComment/TxComment';
import FormatAmount from '../FormatAmount';
import { ActivityFilterTypes } from '../../../dictionaries';

interface Props extends RouteComponentProps {
  accountId?: string;
  txHash?: string;
}

const TransactionDetails: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const accounts = useSelector(getAccounts);
  const txs = useSelector(getTxs);
  const activityFilters = useSelector(getActivityFilters);
  const accountId =
    props.accountId ||
    (activityFilters.type === ActivityFilterTypes.Account &&
      activityFilters.value) ||
    accounts?.allIds[1] ||
    null;
  const txHash =
    props.txHash || (accountId && txs[accountId]?.allIds?.[0]) || null;
  const previousTxHash = usePrevious<string>(txHash);
  const account = useSelector(getAccountById(accountId));
  const tx = useSelector(getTxByHash(accountId, txHash));

  useEffect(() => {
    if (txHash && accountId && previousTxHash !== txHash) {
      dispatch(getTxDetails({ accountId, txHash }));
    }
  }, [previousTxHash, tx, accountId, txHash, dispatch]);

  if (!tx || !account) {
    return null;
  }

  return (
    <PageContent>
      <TopUp>
        <TopUpHeader>
          <TopUpHeaderDetails>
            <h3>
              {tx.amount < 0 ? '-' : ''}
              {Math.abs(tx.amount)} {account.coin}
            </h3>
            <span>
              <FormatAmount coin={account.coin} amount={Math.abs(tx.amount)} />
            </span>
          </TopUpHeaderDetails>
          <TopUpHeaderIcon>
            <CurrencyIcon coin={account.coin} size="xl" />
          </TopUpHeaderIcon>
        </TopUpHeader>
        <AdditionalInfo>
          <AdditionalInfoDate label="Date">
            {format(new Date(tx.date), 'h:mm aaaa MMM d, yyyy')}
          </AdditionalInfoDate>
          <AdditionalInfoComment label="Comment">
            {accountId && <TxComment accountId={accountId} txHash={tx.hash} />}
          </AdditionalInfoComment>
        </AdditionalInfo>
      </TopUp>
      <Details>
        <LabeledText label="Status">
          {tx.confirmations ? (
            tx.confirmations > 0 ? (
              'Success'
            ) : (
              'Unconfirmed'
            )
          ) : (
            <Placeholder />
          )}
        </LabeledText>
        <LabeledText label="Confirmations">
          {tx.confirmations ? tx.confirmations : <Placeholder />}
        </LabeledText>
        <LabeledText label="Network fee">
          {tx.fee ? (
            <>
              {tx.fee} {account.coin}
            </>
          ) : (
            <Placeholder />
          )}
        </LabeledText>
      </Details>
      <LabeledText label="From">
        {tx.from ? (
          <CopyText value={tx.from}>
            <Hash breakAll value={tx.from} />
          </CopyText>
        ) : (
          <Placeholder />
        )}
      </LabeledText>
      <LabeledText label="To">
        {tx.to ? (
          <CopyText value={tx.to}>
            <Hash breakAll value={tx.to} />
          </CopyText>
        ) : (
          <Placeholder />
        )}
      </LabeledText>
      <LabeledText label="Hash">
        <ExternalLink
          to={`${config.coins[account.coin].explorerUrl}/transaction/${
            tx.hash
          }`}
        >
          <Hash breakAll value={tx.hash} />
        </ExternalLink>
      </LabeledText>
    </PageContent>
  );
};

export default withRouter(TransactionDetails);
