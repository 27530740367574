import React from 'react';

import {
  Container,
  DashboardContent,
  Main,
  OneColumnContainer,
  OneColumnContent,
  Sidebar,
  TwoColumnsContainer,
  TwoColumnsContent,
} from './styled';
import Scrollbar from '../../shared/Scrollbar/Scrollbar';
import { MainThumbYEl, MainTrackY } from '../../shared/Scrollbar/styled';
import ClosePageButton from './TopRightCornerButton/ClosePageButton';
import { Breakpoints } from '../../../dictionaries';

export enum PageLayouts {
  oneColumn = 'one-column',
  twoColumns = 'two-columns',
  dashboard = 'dashboard',
}

const containerComponents = {
  [PageLayouts.oneColumn]: OneColumnContainer,
  [PageLayouts.twoColumns]: TwoColumnsContainer,
  [PageLayouts.dashboard]: Container,
};

const contentComponents = {
  [PageLayouts.oneColumn]: OneColumnContent,
  [PageLayouts.twoColumns]: TwoColumnsContent,
  [PageLayouts.dashboard]: DashboardContent,
};

interface Props {
  layout: PageLayouts;
  children: React.ReactNode;
  sidebarContent: React.ReactElement<any>;
  mobileBreakpoint?: Breakpoints;
  hideClosePageButton?: boolean;
}

const Page: React.FC<Props> = ({
  layout,
  children,
  sidebarContent,
  mobileBreakpoint,
  hideClosePageButton,
}) => {
  const ContainerLayout = containerComponents[layout];
  const ContentLayout = contentComponents[layout];

  return (
    <ContainerLayout>
      <Main>
        <Scrollbar ThumbY={MainThumbYEl} TrackY={MainTrackY}>
          <ContentLayout>{children}</ContentLayout>
        </Scrollbar>
      </Main>
      <Sidebar mobileBreakpoint={mobileBreakpoint}>{sidebarContent}</Sidebar>
      {!hideClosePageButton &&
        (layout === PageLayouts.oneColumn ||
          layout === PageLayouts.twoColumns) && (
          <ClosePageButton mobileBreakpoint={mobileBreakpoint} />
        )}
    </ContainerLayout>
  );
};

Page.defaultProps = {
  mobileBreakpoint: Breakpoints.md,
};

export default Page;
