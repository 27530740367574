import React from 'react';
import { useParams, useLocation } from 'react-router';

import Page, { PageLayouts } from '../../components/layout/Page/Page';
import Activity from '../../components/shared/Activity/Activity';
import TransactionDetails from '../../components/shared/TransactionDetails/TransactionDetails';
import BackButton from '../../components/layout/Page/TopRightCornerButton/BackButton';

const ActivityPage: React.FC = () => {
  const {
    accountId,
    txHash,
  }: { accountId?: string; txHash?: string } = useParams();
  const location = useLocation();
  const isAccountPage = location.state?.fromAccount;

  return (
    <Page
      layout={PageLayouts.oneColumn}
      sidebarContent={
        <Activity accountId={accountId} isAccountPage={isAccountPage} />
      }
      hideClosePageButton={isAccountPage}
    >
      <TransactionDetails accountId={accountId} txHash={txHash} />
      {isAccountPage && <BackButton />}
    </Page>
  );
};

export default ActivityPage;
