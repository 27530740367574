import styled from 'styled-components';

import { Breakpoints } from '../../../dictionaries';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  
  @media (min-width: ${Breakpoints.md}px) {
    padding: 3.5rem 1.5rem 0;
  }

  @media (min-width: ${Breakpoints.xl}px) {
    padding: 3.5rem 3rem 0;
  }
`;
