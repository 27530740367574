import React from 'react';
import { X as CloseIcon } from 'react-feather';
import { useHistory } from 'react-router';

import { Button } from './styled';
import { Breakpoints } from '../../../../dictionaries';

interface Props {
  className?: string;
  mobileBreakpoint?: Breakpoints;
  onClick: () => void;
  children: React.ReactNode;
}

const TopRightCornerButton: React.FC<Props> = ({
  className,
  mobileBreakpoint,
  onClick,
  children,
}) => {
  return (
    <Button
      onClick={onClick}
      className={className}
      mobileBreakpoint={mobileBreakpoint}
    >
      {children}
    </Button>
  );
};

TopRightCornerButton.defaultProps = {
  mobileBreakpoint: Breakpoints.md,
};

export default TopRightCornerButton;
